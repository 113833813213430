.container {
  @apply fixed inset-0 m-auto flex items-center justify-center w-full sm:max-w-screen-mobile transition-opacity duration-200;
}

.overlay {
  @apply fixed inset-0 m-auto w-full sm:max-w-screen-mobile bg-black bg-opacity-40 transition-opacity duration-200;
}

.cubeGrid {
  @apply w-10 h-10 m-auto transition-opacity duration-200;
}

.cube {
  @apply w-1/3 h-1/3 float-left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.cube:nth-child(1) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.cube:nth-child(2) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.cube:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.cube:nth-child(4) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.cube:nth-child(5) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.cube:nth-child(6) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.cube:nth-child(7) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.cube:nth-child(8) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.cube:nth-child(9) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
