:root {
  /* --color-primary: #background: #0080FF; */
  /* --color-primary: 21, 153, 23 !important; */
  --text-color-default: 67 67 67;
  --bg-color-default: 255 255 255;
  --color-custombg-50: 255 255 255;
  --color-custombg-100: 255 255 255;
  --color-custombg-200: 255 255 255;
  --color-custombg-300: 255 255 255;
  --color-custombg-500: 255 255 255;
  --color-custombg-600: 255 255 255;
  --color-custombg-700: 255 255 255;
  --color-primary-50: 252, 240, 240;
  --color-primary-100: 246, 203, 190;
  --color-primary-200: 242, 169, 149;
  --color-primary-300: 237, 137, 108;
  --color-primary-400: 235, 111, 78;
  --color-primary-500: 178, 4, 4;
  --color-primary-600: 222, 83, 47;
  --color-primary-700: 127, 4, 4;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

@font-face {
  font-family: 'Fazz Neue';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/FazzNeue-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Fazz Neue';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/FazzNeue-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Fazz Neue';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/FazzNeue-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'RDSans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/RDSans-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'RDSans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/RDSans-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'RDSans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('/fonts/RDSans-ExtraBold.ttf') format('ttf');
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-size: 0.875rem;
  font-family: 'DM Sans', sans-serif;
  background-color: #f9f9f9;
  color: rgba(var(--text-color-default));
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.text-black {
  color: rgba(var(--text-color-default)) !important;
}

/* input { */
/* background-color: rgb(var(--bg-color-default)); */
/* } */

a {
  text-decoration: none;
  color: inherit;
}

svg {
  display: inline-block;
}

hr {
  @apply my-4 border-0 border-t border-gray-200;
}

/*
 * Utilities
 */

.badge {
  padding: 8px;
  color: #ffffff;
  font-size: 1em;
  border-radius: 16px;
  font-weight: normal;
}

.text-green {
  color: #18ba62;
}

/* FORMS */
.input-icon-right {
  position: absolute;
  top: 10px;
  right: 26px;
  z-index: 2;
  width: 18px;
  height: 18px;
  color: #9e9e9e;
}

input.form-control.username,
input.form-control.password {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
}

.input-group.username:focus-within,
.input-group.password:focus-within {
  outline: 0;
  box-shadow: 0px 0px 0px 4px rgba(0, 128, 255, 0.25);
  border-radius: 4px;
}

input.form-control.username,
input.form-control.password {
  border-left: 0;
}

input.form-control.password {
  border-right: 0;
}

input.form-control::placeholder,
input.form-control::placeholder {
  color: #e0e0e0;
}

.input-group--readOnly * {
  background-color: #e9ecef !important;
}

.input-group-text {
  background-color: transparent;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 26px;
  color: #fff;
  cursor: pointer;
  margin: 0 !important;
}

.switch--disabled {
  cursor: not-allowed;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.switch-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 26px;
  background-color: #9e9e9e;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.switch-slider:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.switch.is-active .switch-slider {
  background-color: var(--color-primary);
  padding-left: 12px;
}

.switch.is-active .switch-slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}

/* Switch Small */
.switch--small {
  width: 56px;
  height: 26px;
}

.switch--small input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch--small .switch-slider:before {
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 2px;
}

.switch--small.is-active .switch-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.text-grey {
  color: #9e9e9e;
}

.form-control:focus {
  box-shadow: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 1px solid #ced4da;
}

.floating-hover:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px 0px inset;
  cursor: pointer;
}

.dropdown-toggle::after {
  display: none;
}


.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden;
  /* prevents background page from scrolling when the modal is open */
}

.card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1.8px 2px 0.6px;
  border: none;
  border-radius: 4px;
}

.highlight-input-border {
  border: none !important;
  border-bottom: 2px solid var(--color-primary) !important;
  background-color: #F7E9E7 !important;
}

.material-input {
  border: none !important;
  border-radius: 0px;
  border-bottom: 1px solid #9e9e9e !important;
}

.material-input::placeholder,
.material-input-placeholder {
  color: #b9b9b9;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* Snackbar theming */
.alert {
  border: 0 !important;
}

.alert-primary,
.alert-success {
  color: #0080FF !important;
  background-color: #E2F1FF !important;
}

.alert-danger {
  color: #EB6F4E !important;
  background-color: #F7E9E7 !important;
}

.alert-info {
  color: #434343 !important;
  background-color: #F5F5F5 !important;
}

.alert-warning {
  color: #FFD300 !important;
  background-color: #FFFDE5 !important;
}

/* Pagination */
.pagination {
  @apply flex justify-center text-gray border-gray;
}

.pagination .previous {
  @apply mr-1;
}

.pagination .next {
  @apply ml-1;
}

.pagination li a {
  @apply py-2 px-4 rounded border border-gray transition-opacity duration-200 hover:opacity-50;
}

.pagination .active {
  @apply text-black border-black
}

.rdrDefinedRangesWrapper {
  display: none;
}

@layer utilities {

  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(200px);
  }

  100% {
    transform: translateY(0);
  }
}

/* Voucher */
.voucher-item {
  @apply flex min-h-[80px] bg-white rounded-lg cursor-pointer mb-4;
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
}

.voucher-item__icon {
  @apply bg-primary px-5 py-0 max-w-[80px] flex flex-col items-center justify-center;
  @apply border-r border-white border-dashed rounded-tl-lg rounded-bl-lg;
  @apply font-bold text-center text-white;
}

.voucher-item__content {
  @apply flex flex-1 flex-row justify-between border border-white rounded-tr-lg rounded-br-lg;
}

.voucher-item.default,
.voucher-item.disabled {
  @apply cursor-default border border-white;
}

.voucher-item.hovered:hover .voucher-item__content,
.voucher-item.selected .voucher-item__content {
  @apply bg-primary-50 border border-primary border-l-white;
}

.voucher-item.disabled .voucher-item__icon {
  @apply bg-line-gray;
}

.voucher-summary {
  @apply h-auto fixed bottom-0 bg-white w-full p-4 flex justify-between items-center text-body1;
}

.background-style {
  background: #7E0303 !important;
  background-image: url('/img/common/jooalanwhite.svg') !important;
  color: #ffffff !important;
  text-align: center;
  background-position: calc(100% + 600px) calc(100% + 100px) !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}

.background-main-kelas {
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
  background: rgba(255, 255, 255, 0.6);
  border: 2px solid #FFFFFF;
  backdrop-filter: blur(2px);
  border-radius: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.background-main-style {
  color: #000000 !important;
  text-align: center;
  background-image: url('/img/common/ellipsetop.png'), url('/img/common/ellipsebottom.png') !important;
  background-size: cover, cover !important;
}

.background-white-style {
  background: #ffffff !important;
  color: #B20404 !important;
  text-align: center;
}

.background-white-accordion {
  background: #ffffff !important;
  color: #000000 !important;
  text-align: center;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #F2F1F0;
}

.button-style {
  background-color: #B20404 !important;
  border-radius: 9999px !important;
}

.text-maroon {
  color: #B20404 !important;
}

.logo-style {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.carousel__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}

.carousel__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.carousel__dot:after {
  @apply bg-gray-300;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}

.carousel__dot--selected:after {
  @apply bg-primary;
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
}