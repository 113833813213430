.container {
  @apply flex items-center justify-center;
}

.snackbar {
  @apply border-0 rounded-lg fixed top-4 p-4 w-full;
  z-index: 999;
  width: calc(100% - 24px);
  max-width: calc(480px - 24px); /* 480px is max screen mobile */
}

.success {
  @apply text-[#29AB87] bg-[#E0F3EE];
}

.danger {
  @apply text-[#EB6F4E] bg-[#F7E9E7];
}

.info {
  @apply text-[#434343] bg-[#F5F5F5];
}

.warning {
  @apply text-[#FFD300] bg-[#FFFDE5];
}
